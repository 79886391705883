<script setup lang="ts">
import FileUploadV2 from '~/components/design-system/file-upload-v2.vue';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
const { $t } = useNuxtApp();
const { $complianceService } = useServices();
import { accordionTabPassthrough } from '~/components/passthroughs/accordion';
import { toastPassThrough } from '~/components/passthroughs/toast';

import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';

import GenericTag from '../user-interface/generic-tag.vue';
import { type TagTypes } from '../user-interface/generic-tag.vue';
import { DocumentIcon, TrashIcon } from '@heroicons/vue/24/outline';

interface IUploadedDocs {
  thumbnail?: string;
  documentId: string;
  documentName: string;
  documentType: number;
  documentStatus: number;
  dateRequested: Date;
  documentSize: number;
  externalSystemId: string;
  documentStatusDescription: string;
}

interface IAccordionProps {
  header: string;
  headerStatus?: number;
  documents?: Partial<IUploadedDocs>[];
  documentType: string | number;
  value: string;
}

const toast = useToast();
const props = withDefaults(defineProps<IAccordionProps>(), {
  value: '0',
  headerStatus: 3,
});
const emits = defineEmits(['uploadFile', 'deleteFile']);
const isUploading = ref(false);
const isDeleting = ref(false);

const tagType: ComputedRef<TagTypes> = computed(() => {
  switch (props.headerStatus) {
    case 0:
      return 'awaiting-upload';
    case 1:
      return 'pending-review';
    case 2:
      return 'validated';
    default:
      return 'document-rejected';
  }
});

const statusColor = computed(() => {
  return (status: number) => {
    switch (status) {
      case 0:
        return {
          bg: 'bg-grey-100',
          text: 'text-grey-800',
        };
      case 1:
        return {
          bg: 'bg-warning-500',
          text: 'text-warning-500',
        };
      case 2:
        return {
          bg: 'bg-primary-blue-500',
          text: 'text-primary-blue-600',
        };
      default:
        return {
          bg: 'bg-error-500',
          text: 'text-error-500',
        };
    }
  };
});
//event: FileUploadUploadEvent
function uploadFile(document, thumbnail) {
  emits('uploadFile', document, thumbnail);
}

function activateLoader(flag: true) {
  isUploading.value = flag;
}

async function deleteFile(document: IUploadedDocs) {
  isDeleting.value = true;
  //make call
  //Api call for delete
  await $complianceService.deleteDocument(document.documentId).then((data) => {
    if (data?.isSuccessful) {
      //If Successful
      toast.add({
        severity: 'success',
        summary: $t('success'),
        detail: `${$t('jpc-doc-ver-file')} ${document.documentName} ${$t(
          'jpc-doc-ver-deleted'
        )}`,
        life: 3000,
        group: 'toast',
      });
      emits('deleteFile', document);
    } else {
      // display error
      toast.add({
        severity: 'error',
        summary: $t('error'),
        detail: $t('unable-to-delete-file'),
        life: 3000,
        group: 'toast',
      });
    }
  });

  isDeleting.value = false;
}
</script>

<template>
  <Toast position="bottom-center" group="toast" :pt="toastPassThrough" />
  <AccordionPanel :pt="accordionTabPassthrough.uploadAccordion" :value="value">
    <AccordionHeader :pt="accordionTabPassthrough.uploadHeaderAction">
      <div class="flex flex-row items-center">
        <p class="my-2 font-bold text-base-priority">
          {{ $t(props.header) }}
        </p>
        <GenericTag
          v-if="
            tagType &&
            !(header === 'bank-verification' && props.headerStatus === 0)
          "
          icon-only
          :type="tagType"
          position="none"
          :icon-dimensions="24"
          title-class="text-base-size"
          class="!rounded-lg ml-3"
        />
      </div>
    </AccordionHeader>
    <AccordionContent :pt="accordionTabPassthrough.uploadContent">
      <div class="text-xs dark:text-white pt-2 px-2 sm:px-4 sm:pt-3">
        <slot name="accordionContent" />
      </div>
      <div class="dark:text-white w-full">
        <p class="ml-4 mb-3 text-xs font-bold">
          {{
            props.documents.length > 0
              ? $t('your-documents')
              : props.headerStatus === 2
              ? ''
              : $t('please-upload-documents')
          }}
        </p>
        <div
          v-if="props.documents && props.documents.length > 0"
          class="ml-1.5 mr-1.5"
          :class="isDeleting ? 'blur-4' : 'blur-0'"
        >
          <LazyUserInterfaceGenericLoader v-if="isDeleting" container />
          <div
            v-for="(doc, index) in props.documents"
            :key="index"
            class="flex items-center justify-between w-full dark:bg-dark-900 rounded-md px-2 sm:pr-3 mb-2"
          >
            <div class="flex">
              <div class="mr-2 flex justify-center items-center">
                <img
                  v-if="doc?.thumbnail"
                  role="presentation"
                  :src="doc?.thumbnail"
                  :alt="doc.documentName"
                  width="24"
                  height="24"
                  class="shadow-2"
                />
                <DocumentIcon v-else class="w-4" />
              </div>
              <div class="flex flex-col max-w-[145px] sm:max-w-none">
                <p
                  class="mt-2 mb-0 font-bold text-xs overflow-hidden text-ellipsis"
                  :class="statusColor(doc.documentStatus).text"
                >
                  {{ doc.documentName }}
                </p>
                <div class="flex flex-row text-xs">
                  <p class="my-0 font-semibold mr-1 capitalize">
                    {{ $t('requested') }}:
                  </p>
                  <p class="mt-0 mb-2">
                    {{
                      new Date(doc.dateRequested)
                        .toLocaleDateString('en-GB')
                        .replaceAll('/', '-')
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="status-div ml-2 sm:ml-3 text-nowrap">
              <div class="flex flex-col sm:flex-row">
                <p class="my-0 font-bold text-xs mr-1">{{ $t('status') }}:</p>
                <div class="flex mr-2.5">
                  <p
                    class="font-normal text-xs w-full mr-1 sm:mr-2 my-0"
                    :class="statusColor(doc.documentStatus).text"
                  >
                    {{ $t(doc.documentStatusDescription || 'pending') }}
                  </p>
                  <div
                    class="dot flex shrink-0 self-center"
                    :class="statusColor(doc.documentStatus).bg"
                  />
                </div>
              </div>

              <TrashIcon
                class="w-4 cursor-pointer text-dark-700 dark:text-white"
                @click="deleteFile(doc)"
              />
            </div>
          </div>
        </div>
        <div
          class="flex flex-col items-center flex-wrap w-full bg-primary-layer rounded-md"
          :class="isUploading ? 'blur-4' : 'blur-0'"
        >
          <LazyUserInterfaceGenericLoader v-if="isUploading" container />

          <FileUploadV2
            v-if="props.headerStatus !== 2"
            :document-type="props.documentType"
            @upload-file="uploadFile"
            @uploading="activateLoader"
          />
        </div>
      </div>
    </AccordionContent>
  </AccordionPanel>
</template>

<style scoped>
.dot {
  @apply w-2 h-2 rounded-full animate-pulse;
}
.status-div {
  cursor: default;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 4px;
  height: fit-content;
}

.footer {
  width: 100%;
  padding-top: 12px;

  padding-bottom: 24px;
}
</style>

import type { PassThrough } from '@primevue/core';
import type { FileUploadPassThroughOptions } from 'primevue/fileupload';

export const fileUploadPassthrough: PassThrough<FileUploadPassThroughOptions> =
  {
    header: {
      class: 'border-none',
    },
    content: ({ state }) => ({
      class: [
        'mb-3',
        { 'absolute top-5 left-1/2 -translate-x-2/4': state?.files.length < 1 },
      ],
    }),
  };
